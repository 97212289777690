<template>
  <div>
    <form @submit.prevent="createUser" class="">
      <div class="row">
        <div class="col-3 mb-3">
          <label for="name" class="form-label"> Nom complet :</label>
          <input
            type="text"
            id="name"
            v-model="user.name"
            class="form-control"
            required
          />
        </div>

        <div class="col-3 mb-3">
          <label for="email" class="form-label"> E-mail :</label>
          <input
            type="text"
            id="email"
            v-model="user.email"
            class="form-control"
          />
        </div>

        <div class="col-3 mb-3">
          <label for="password" class="form-label"> Mot de passe :</label>
          <input
            type="password"
            id="password"
            v-model="user.password"
            class="form-control"
            required
          />
        </div>
      </div>

      <button type="submit" class="btn btn-primary">
        Modier l'utilisateur
      </button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
    };
  },
  computed: {},
  methods: {
    async createUser() {
      await this.$store.dispatch("user/create", this.user);
    },
  },
};
</script>
